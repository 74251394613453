export default () => ({
    selectedTechnique: null,

    onClick(event) {
        if (event.target.value === this.selectedTechnique) {
            this.selectedTechnique = null
        }
    },

    showArtistsByTechnique(technique) {
        const artists = [...this.$refs.list.children]

        artists.forEach((artist) => {
            const techniques = artist.dataset.techniques.split(',')

            artist.classList.toggle(
                'hidden',
                technique && !techniques.includes(technique)
            )
        })
    },

    init() {
        this.$watch('selectedTechnique', this.showArtistsByTechnique.bind(this))
    },
})
