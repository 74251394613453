import { Loader } from '@googlemaps/js-api-loader'

export default ({ apiKey = '', points = [] }) => ({
    apiKey,
    zoom: 11,
    map: null,
    markers: [],
    selectedType: 'locations',
    activePanelIds: [],
    isLoaded: false,

    onClick(event) {
        const allowToDeselect = !window.matchMedia('(min-width: 768px)').matches

        if (allowToDeselect && event.target.value === this.selectedType) {
            this.selectedType = null
        }
    },

    set activePanelId(value) {
        if (value) {
            this.activePanelIds = [...this.activePanelIds, value]
        } else {
            this.activePanelIds = [...this.activePanelIds.slice(0, -1)]
        }
    },

    get activePanelId() {
        return this.activePanelIds[this.activePanelIds.length - 1] ?? null
    },

    async showPanel(panelId, clearHistory) {
        const scroller = this.$refs.scroller
        const callback = () => {
            if (clearHistory) {
                this.activePanelIds = []
            }

            this.activePanelId = panelId
        }

        return new Promise((resolve) => {
            if (scroller.scrollTop) {
                scroller.addEventListener(
                    'scrollend',
                    () => {
                        callback()
                        resolve()
                    },
                    { once: true }
                )
                scroller.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                })
            } else {
                callback()
            }
        })
    },

    calculatePadding() {
        const media = window.matchMedia('(min-width: 768px)')
        const base = 32

        return {
            top: base,
            right: base,
            bottom: media.matches ? base : 154,
            left: media.matches ? 448 : base,
        }
    },

    init() {
        const activePanelId =
            parseInt(window.location.hash.substring(1)) ?? null
        const pinElement = this.$refs.pin
        const mapElement = this.$refs.map
        const loader = new Loader({
            apiKey: this.apiKey,
            version: 'weekly',
            libraries: ['maps', 'marker'],
        })

        this.$watch('activePanelId', (id) => {
            // TODO: Working temporarily
            const activeMarker =
                this.markers
                    .filter(
                        (marker) =>
                            marker.id == id || marker.artistIds.includes(id)
                    )
                    .at(-1) ?? null

            this.markers.forEach((marker) => {
                const isActiveMarker = marker === activeMarker
                const pin = marker.content.querySelector('[data-pin]')

                pin.classList.toggle('fill-white', !isActiveMarker)
                pin.classList.toggle('fill-black', isActiveMarker)
                marker.zIndex = isActiveMarker ? 1 : 0
                marker.content.style = isActiveMarker
                    ? 'transform: scale(1.5);'
                    : ''
            })

            if (activeMarker) {
                this.map.panTo(activeMarker.position)
                this.map.setZoom(activeMarker.zoom)
            }

            window.history.replaceState(
                null,
                '',
                id
                    ? `#${id}`
                    : window.location.pathname + window.location.search
            )
        })

        loader
            .load()
            .then(() => {
                const map = new google.maps.Map(mapElement, {
                    mapId: '38686286c39e1361',
                    zoom: this.zoom,
                    mapTypeId: google.maps.MapTypeId.ROADMAP,
                    zoomControl: true,
                    zoomControlOptions: {
                        position: google.maps.ControlPosition.RIGHT_TOP,
                    },
                    cameraControl: false,
                    mapTypeControl: false,
                    scaleControl: false,
                    streetViewControl: false,
                    rotateControl: false,
                    fullscreenControl: false,
                })
                const bounds = new google.maps.LatLngBounds()
                const markers = points
                    .filter((point) => point.position)
                    .map(({ ID, post_title, position, artistIds }) => {
                        const pin = pinElement.cloneNode(true)
                        const marker =
                            new google.maps.marker.AdvancedMarkerElement({
                                map: map,
                                position: {
                                    lat: position.lat,
                                    lng: position.lng,
                                },
                                title: post_title,
                                content: pin,
                            })

                        marker.zoom = position.zoom
                        marker.id = ID
                        marker.artistIds = artistIds
                        google.maps.event.addListener(marker, 'click', () => {
                            this.showPanel(ID, true)
                        })

                        return marker
                    })

                markers.forEach((marker) => {
                    bounds.extend(marker.position)
                })

                if (markers.length === 1) {
                    map.setCenter(bounds.getCenter())
                } else {
                    map.fitBounds(bounds, this.calculatePadding())
                }

                this.map = map
                this.markers = markers
                this.activePanelId = activePanelId

                setTimeout(() => {
                    this.isLoaded = true
                }, 500)
            })
            .catch((error) => {
                console.error(error)

                this.isLoaded = true
            })
    },
})
