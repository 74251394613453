import { scrollend } from 'scrollyfills'
import Alpine from 'alpinejs'
import header from '@/components/header.js'
import video from '@/components/video.js'
import accordion from '@/components/accordion.js'
import embedVideo from '@/components/embed/video.js'
import artists from '@/components/artists.js'
import map from '@/components/map.js'

//
// Fonts
//
import '@fontsource/chakra-petch/500.css'
import '@fontsource/chakra-petch/600.css'
import '@fontsource-variable/open-sans'

//
// Styles
//
import '../css/style.css'

//
// Setup Alpine
//
window.Alpine = Alpine

//
// Setup Alpine components
//
Alpine.data('header', header)
Alpine.data('video', video)
Alpine.data('accordion', accordion)
Alpine.data('embedVideo', embedVideo)
Alpine.data('artists', artists)
Alpine.data('map', map)

//
// Initialize Alpine
//
Alpine.start()

//
// Staggered appear animations
//
const staggers = [...document.querySelectorAll('[data-stagger]')]
const onIntersecting = (target) => {
    const items = [...target.querySelectorAll('[data-appear]')]

    items.forEach((item, index) => {
        const className = item.dataset.appear
        const delay = (parseInt(item.dataset.appearDelay) || 0) + index * 100
        const onAnimationEnd = (event) => {
            event.target.classList.remove(className)
            event.target.removeAttribute('data-appear')
            event.target.removeEventListener('animationend', onAnimationEnd)
        }

        item.addEventListener('animationend', onAnimationEnd)
        item.style.animationDelay = `${delay}ms`
        item.classList.add(className)
    })
}
const observer = !!window.IntersectionObserver
    ? new IntersectionObserver(
          (entries, observer) => {
              for (const entry of entries) {
                  if (entry.intersectionRatio > 0) {
                      onIntersecting(entry.target)
                      observer.unobserve(entry.target)
                  }
              }
          },
          {
              rootMargin: '0% 0% -15% 0%',
              threshold: 0,
          }
      )
    : null

staggers.forEach((stagger) => {
    if (observer) {
        observer.observe(stagger)
    } else {
        onIntersecting(stagger)
    }
})
